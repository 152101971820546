import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import { formatUTCToLocal } from "@/common/date-format";
import SchedulerApiService from "@/core/services/api.service.scheduler";
import AddLimitation from "./offcanvas/add-limitation.vue";
import { ACTIVE_HEADERS, ARCHIVE_HEADERS } from "./wheel-tables";
import WheelsApiService from "@/core/services/api.service.wheel";
import ConfirmDialog from "@/view/pages/ConfirmDialog.vue";
export default {
  name: "wheel-of-fortune",
  components: { AddLimitation, ConfirmDialog },
  data() {
    return {
      pagination: {},
      activeCampaigns: [],
      activeCampaignsLoading: false,
      archiveCampaigns: [],
      archiveCampaignsLoading: false,
      paginationArchive: {},
      total: 0,
      totalArchive: 0,
      countries: [],
      componentLoaded: false,
      countriesLoading: false,
      isCopied: false
    };
  },
  watch: {
    pagination: {
      handler() {
        if (!this.componentLoaded) return;
        this.getActiveWheelCampaigns();
      },
      deep: true
    },
    paginationArchive: {
      handler() {
        if (!this.componentLoaded) return;
        this.getArchiveWheelCampaigns();
      },
      deep: true
    },
    shopDataPagination: {
      handler() {
        if (!this.componentLoaded) return;
        this.getVoucherShops();
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    name() {
      return this.$i18n.t("menu.wheel");
    },
    headers() {
      return ACTIVE_HEADERS({ i18n: this.$i18n });
    },
    archiveHeaders() {
      return ARCHIVE_HEADERS({ i18n: this.$i18n });
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    await WheelsApiService.init();

    this.getActiveWheelCampaigns();
    this.getArchiveWheelCampaigns();

    this.componentLoaded = true;
  },
  methods: {
    async delRecord(item) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete campaign: " + item.name + " ?"
        )
      ) {
        this.onDeleteItem(item);
      }
    },
    onOpenFlyout(event, type) {
      const update = {
        data: event ? { ...event } : undefined,
        type
      };

      this.$refs.addWheelFlyout.open(update);
    },
    onFlyoutSave() {
      this.getActiveWheelCampaigns();
      this.getArchiveWheelCampaigns();
    },

    async getActiveWheelCampaigns() {
      const config = {
        params: {
          isActive: true,
          pageNumber: this.pagination.page - 1,
          pageSize: this.pagination.itemsPerPage
        }
      };

      this.activeCampaignsLoading = true;
      await WheelsApiService.getWheelCampaigns(config)
        .then(res => {
          this.activeCampaigns = res.data?.campaigns?.results || [];
          this.total = res.data?.campaigns?.totalCount || 0;
          //   console.log("Aktivne kapmanje:", this.activeCampaigns);
        })
        .finally(() => {
          this.activeCampaignsLoading = false;
        });
    },
    async getArchiveWheelCampaigns() {
      const config = {
        params: {
          isActive: false,
          pageNumber: this.paginationArchive.page - 1,
          pageSize: this.paginationArchive.itemsPerPage
        }
      };

      this.archiveCampaignsLoading = true;
      await WheelsApiService.getWheelCampaigns(config)
        .then(res => {
          this.archiveCampaigns = res.data?.campaigns?.results || [];
          this.totalArchive = res.data?.campaigns?.totalCount || 0;
          // console.log("Arhivirane kapmanje:", this.archiveCampaigns);
        })
        .finally(() => {
          this.archiveCampaignsLoading = false;
        });
    },

    formatUTCToLocal: formatUTCToLocal,
    onDeleteItem(item) {
      this.onDelete(item).then(() => {
        this.getActiveWheelCampaigns();
        this.getArchiveWheelCampaigns();
      });
    },
    onDeleteArchiveItem(item) {
      this.onDelete(item).then(() => {
        this.getArchiveWheelCampaigns();
      });
    },
    async onDelete(item) {
      return await WheelsApiService.deleteWheelCampaign(item.id).then(() => {
        this.$bvToast.toast(this.$i18n.t("common.savedSuccessfully"), {
          title: this.$i18n.t("menu.wheel"),
          solid: true,
          variant: "success",
          autoHideDelay: 1000
        });

        return true;
      });
    },
    getCountries() {
      this.countriesLoading = true;
      return SchedulerApiService.getCountries()
        .then(res => {
          this.countries = res.data || [];
        })
        .finally(() => {
          this.countriesLoading = false;
        });
    },
    getItemLink(item) {
      return `https://ooblee.com/${item.countryCode}/wheel-of-fortune/${item.id}`;
    },
    openLinkInNewWindow(item) {
      const link = this.getItemLink(item);
      window.open(link, "_blank");
    },
    copyLink(item) {
      const link = this.getItemLink(item);
      navigator.clipboard.writeText(link);
      this.isCopied = true;

      setTimeout(() => {
        this.isCopied = false;
      }, 2500);
    }
  }
};
